import React from "react";
import styles from './ArrowIcon.module.css';

interface Props {
  active?: boolean;
}

const ArrowIcon: React.FC<Props> = ({ active = false }) => {
  return (
    <div>
    <svg
      className={`${styles.icon} ${active ? styles.rotate : ''}`}
      xmlns="http://www.w3.org/2000/svg"
      width="501"
      height="298"
      fill="none"
      viewBox="0 0 501 298"
    >
      <g filter="url(#filter0_d)">
        <path
          fill="#000"
          d="M269.2 7.848l218.944 218.948c5.068 5.064 7.86 11.824 7.86 19.032s-2.792 13.968-7.86 19.032l-16.12 16.124c-10.504 10.492-27.576 10.492-38.064 0L250.104 97.128l-184.06 184.06c-5.068 5.064-11.824 7.86-19.028 7.86-7.212 0-13.968-2.796-19.04-7.86L11.86 265.064C6.792 259.996 4 253.24 4 246.032S6.792 232.064 11.86 227L231.004 7.848C236.088 2.772 242.876-.016 250.092 0c7.244-.016 14.028 2.772 19.108 7.848z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d"
          width="500.004"
          height="297.048"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
    </div>
  );
}

export default ArrowIcon;

