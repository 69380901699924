import React from 'react';
import styles from './AvgGradeBox.module.css';

interface Props {
  grade: number;
  size?: 'mobile' | 'desktop';

}

const AvgGradeBox:React.FC<Props> = ({grade, size='mobile'}) => {
  return (
  <div className={styles.box}>
    <h2 className={styles.content}>Karaktersnitt: <span className={styles.bold}>{grade.toFixed(2)}</span></h2>
  </div>
  )
}

export default AvgGradeBox;
