import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import uuid from 'react-uuid';
import { range } from '../helpers/range';
import styles from './GradeComponent.module.css';

interface Props {
  grade: number;
  id: string;
  field: IField;
  size?: 'sm' | 'lg';
  handleUpdateGrade: (id: string, grade: number, field: IField) => void;
}

const GradeComponent:React.FC<Props> = ({grade, id, field, size="sm", handleUpdateGrade}) => {
  return (
  <>
   <ButtonGroup size={size}>
     {(range(1,6,1)).map((val: number, index: number) => (
       <button key={uuid()} className={`${styles.button} ${val === grade ? styles.active : ''} ${size === 'lg' ? styles.large : ''}`} onClick={() => handleUpdateGrade(id, val, field)} >{val}</button>
     ))}
    </ButtonGroup>
  </>
  )
}

export default GradeComponent
