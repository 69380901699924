import React from 'react';
import bgImage from '../images/background.jpg';
import kubenLogo from '../images/favicon.png';
import styles from './Header.module.css';

interface Props {

}

const Header = () => {
  return (
    <div className={styles.container}>
      <img className={styles.image} src={bgImage} alt="Kuben videregående skole" />
      <div className={styles.imgContainer}><img className={styles.logo} src={kubenLogo} alt="Kuben logo"/></div>
    </div>
  )
}

export default Header
