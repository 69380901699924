import React from 'react';
import { Table } from 'react-bootstrap';
import Button from './Button';
import GradeComponent from './GradeComponent';
import styles from './SubjectsTable.module.css';

interface Props {
  rows: ISubject[];
  handleUpdateSubjects: (id: string) => void;
  handleUpdateGrade: (id: string, grade: number, field: IField) => void;
}


const SubjectsTable: React.FC<Props> = ({rows, handleUpdateSubjects, handleUpdateGrade}) => {
  return (
    <>
    <Table responsive borderless>
  <thead>
    <tr className={styles.tableRow}>
      <th>Fag</th>
      <th>Standpunktskarakter</th>
      <th>Eksamenskarakter</th>
      <th>Fjern fag</th>
    </tr>
  </thead>
  <tbody>
  {rows.map((row: any, index: number) => (<tr key={index}><td>{row.label}</td><td><GradeComponent grade={row.standpunkt} field='standpunkt' id={row.id} handleUpdateGrade={handleUpdateGrade}/></td><td><GradeComponent grade={row.eksamen} field='eksamen' id={row.id} handleUpdateGrade={handleUpdateGrade}/></td><td><Button clickHandler={handleUpdateSubjects} id={row.id}></Button></td></tr>))}
  </tbody>
</Table>
</>
  )
}

export default SubjectsTable;
