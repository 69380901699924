import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Nav from '../components/Nav';
import SubjectsContainer from '../components/SubjectsContainer';
import '../styles/global.css';
import styles from './Index.module.css';

const IndexPage = () => {
  return (
    <>
    <Nav />
    <Container className="mt-4" style={{backgroundColor: 'white'}}>
    <Header />
    <img className={styles.logo} src={'/karkalk.png'} alt='karakterkalkulator' />
    <SubjectsContainer />
    </Container>
  </>
  )
}

export default IndexPage
