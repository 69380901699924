
export const avgGrade = (subjects: ISubject[]) => {
  let totalGrades = 0;
  let numberOfGrades = 0;
  subjects.forEach((subj: ISubject) => {
    if (subj.standpunkt !== 0){
      totalGrades += subj.standpunkt;
      numberOfGrades += 1;
    }

    if (subj.eksamen !== 0){
      totalGrades += subj.eksamen;
      numberOfGrades += 1;
    }

  });
  if (numberOfGrades == 0 || totalGrades == 0) {
    return 0;
  }

  return totalGrades/numberOfGrades;
}
