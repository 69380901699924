import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import uuid from 'react-uuid';
import styles from './PossibleStudies.module.css';

interface Props {
  grade: number;
}

interface RowProps extends Props {
  lenke: string;
  title: string;
  avgGrade: number;
  description?: string;
}

const Row: React.FC<RowProps> = ({ grade, lenke, title, avgGrade, description}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return <>
  <div className={`${styles.row} ${grade <= avgGrade ? styles.pass : styles.fail}`} onClick={() => setIsExpanded(!isExpanded)}><div className={styles.expandedSectionRow}><div className={styles.study}>{title}</div><div className={styles.limit}>Siste års inntakskrav: <b>{grade.toFixed(2)}</b></div></div></div>
  {isExpanded &&
  <div className={styles.expandedSection}>
  <div className={styles.expandedSectionRow}>{grade <= avgGrade ? <><b>Bra jobbet! Fortsett slik så tror vi at du kommer inn.</b></> : <><b>Stå på! Du er {(grade - avgGrade).toFixed(2)} unna inntakskravet.</b></>}</div>
  <div className={styles.expandedSectionDescription}>
    <p>{description}</p>
    <p><a href={lenke} target="_blank" ><button className={styles.addBtn}>Les mer her</button></a></p>
    </div>
  </div>}
    </>
}

const PossibleStudies:React.FC<Props> = ({children, grade}) => {
  const data = useStaticQuery(graphql`
    query studieQuery {
      allSanityStudie (sort: {fields: karaktersnitt, order: ASC}) {
        edges {
          node {
            _id
            karaktersnitt
            title
            lenke
            beskrivelse
          }
        }
      }
    }
  `);

  const studyList = data?.allSanityStudie?.edges?.filter((node: any) => node.node.karaktersnitt/10 <= grade).map((node: any, index: number) => {
    return(
      <Row description={node.node.beskrivelse} avgGrade={grade} title={node.node.title} grade={node.node.karaktersnitt/10} lenke={node.node.lenke} key={uuid()} />
    );
  });

    const futureStudyList = data?.allSanityStudie?.edges?.filter((node: any) => node.node.karaktersnitt/10 > grade).map((node: any, index: number) => {
      return(
        <Row description={node.node.beskrivelse} avgGrade={grade} title={node.node.title} grade={node.node.karaktersnitt/10} lenke={node.node.lenke} key={uuid()} />
      );
    });

  return (
  <>
  <div className={styles.container}>
    <>
    {futureStudyList.length !== data?.allSanityStudie?.edges?.length && <h5 className={styles.title}>Vi tror at du kommer inn på: </h5>}
    {studyList}
    {futureStudyList.length !== 0 && <><h5 className={styles.title}>Vi tror du trenger litt høyere snitt for å komme inn på disse:</h5>
    {futureStudyList}</>}
    </>
  </div>
</>
  )
}

export default PossibleStudies;

