import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { subjects as initialSubjects } from '../fixtures/constants';
import { avgGrade as calcAvgGrade } from '../helpers/avgGrade';
import { updateSubj } from '../helpers/updateSubj';
import ArrowIcon from './ArrowIcon';
import AvgGradeBox from './AvgGradeBox';
import MobileSubjectsTable from './MobileSubjectsTable';
import PossibleStudies from './PossibleStudies';
import styles from './SubjectsContainer.module.css';
import SubjectsTable from './SubjectsTable';

interface Props {}

const SubjectsContainer: React.FC<Props> = () => {
  const [subjects, setSubjects] = useState<ISubject[]>(initialSubjects);
  const [newSubject, setNewSubject] = useState<ISubject>({ id: uuid(), label: '', eksamen: 0, standpunkt: 0});
  const [avgGrade, setAvgGrade] = useState<number>(0);
  const [showSubjects, setShowSubjects] = useState<boolean>(true);
  const [showStudies, setShowStudies] = useState<boolean>(true);

  const defaultWindowWidth = 1024;
  const [windowSize, setWindowSize] = useState(defaultWindowWidth);

  useEffect(() => {
    const setWindowWidth = () => {
      if (typeof window !== undefined) {
        setWindowSize(window.innerWidth);
      }
    };

    const handleResize = () => {
      setWindowWidth();
    };

    if (typeof window !== undefined) {
      window.addEventListener('resize', handleResize);
    }

    setWindowWidth();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const currentAvgGrade = calcAvgGrade(subjects);
    if (currentAvgGrade !== avgGrade){
      setAvgGrade(currentAvgGrade);
    }
  }, [subjects]);

  const handleRemoveSubject = (id: string) => {
    const updatedSubjects = subjects.filter((subj: ISubject) => subj.id !== id);
    setSubjects(updatedSubjects);
  }

  const handleSelectGrade = (id: string, grade: number, field: IField) => {
    const updatedSubjects = updateSubj(id, grade, subjects, field);
    setSubjects(updatedSubjects);
  }

  const handleNewSubjName = (label: string) => {
    const newSubj = {...newSubject, label};
    setNewSubject(newSubj);
  }

  const SubjectsContent = windowSize > defaultWindowWidth ? <SubjectsTable rows={subjects} handleUpdateSubjects={handleRemoveSubject} handleUpdateGrade={handleSelectGrade}/> : <MobileSubjectsTable rows={subjects} handleUpdateSubjects={handleRemoveSubject} handleUpdateGrade={handleSelectGrade} />

  return (
    <div className={styles.container}>
      <h4 className="mt-4">Beregn dine karakterer fra ungdomsskolen</h4>
      <p className={styles.p}>Bruk karakterkalkulatoren for å beregne gjennomsnittskarakteren du søker videregående med.
      </p>
      <div className={`${styles.menuButton} ${styles.marginTop}`} onClick={() => setShowSubjects(!showSubjects)}><h5 className={styles.menuBtnTitle}>Fyll inn karakterer</h5><ArrowIcon active={showSubjects}/>
      </div>
      <hr className={styles.hrLine}/>
      {showSubjects &&
      <>
      {subjects.length > 0 ? SubjectsContent : <h5>Legg til fag for å regne ut karaktersnitt</h5>}
        <input className={styles.input} value={newSubject.label} placeholder={"Fagnavn"} onChange={(e) => {
          handleNewSubjName(e.target.value);
        }}></input>
      <button className={styles.addBtn} onClick={() => {
        if (newSubject.label !== ''){
          setSubjects([...subjects, newSubject]);
          setNewSubject({...newSubject, label: '', id: uuid()});
        }
      }}>Legg til fag</button>
      <AvgGradeBox grade={avgGrade} size={windowSize > defaultWindowWidth ? 'desktop' : 'mobile'}/>
      </>}
      <div className={styles.menuButton} onClick={() => setShowStudies(!showStudies)}><h5 className={styles.menuBtnTitle} >Studier på Kuben</h5><ArrowIcon active={showStudies}/>
      </div>
      <hr className={styles.hrLine} />
      {showStudies && <> <p className={styles.p}>Poenggrenser er basert på siste 4 års nedre grense.
      </p><PossibleStudies grade={avgGrade}/></>}
    </div>
  )
}

export default SubjectsContainer
