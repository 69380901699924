export const updateSubj = (id: string, grade: number, subjects: ISubject[], field: IField): ISubject[] => {
  const selectedSubject = subjects.find((subj: ISubject) => subj.id === id);
    if (selectedSubject){
      if (selectedSubject[field] !== grade){
        selectedSubject[field] = grade;
      }
      else {
        selectedSubject[field] = 0;
      }
    }
    const updatedSubjects = subjects.filter((subj: ISubject) => {
      if (subj.id === id){
        return selectedSubject;
      } else {
        return subj;
      }
    });

    return updatedSubjects;
  }
