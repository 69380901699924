import uuid from 'react-uuid';

export const subjects = [
  {
    id: uuid(),
    label: 'Matematikk',
    eksamen: 0,
    standpunkt: 0,
  },
  {
    id: uuid(),
    label: 'Norsk muntlig',
    eksamen: 0,
    standpunkt: 0,
  },
  {
    id: uuid(),
    label: 'Norsk skriftlig',
    eksamen: 0,
    standpunkt: 0,
  },
  {
    id: uuid(),
    label: 'Norsk sidemål',
    eksamen: 0,
    standpunkt: 0,
  },
  {
    id: uuid(),
    label: 'Mat og helse',
    eksamen: 0,
    standpunkt: 0,
  },
  {
    id: uuid(),
    label: 'Engelsk',
    eksamen: 0,
    standpunkt: 0,
  },
  {
    id: uuid(),
    label: 'Kunst og håndverk',
    eksamen: 0,
    standpunkt: 0,
  },
  {
    id: uuid(),
    label: 'Samfunnsfag',
    eksamen: 0,
    standpunkt: 0,
  },
  {
    id: uuid(),
    label: 'KRLE',
    eksamen: 0,
    standpunkt: 0,
  },
  {
    id: uuid(),
    label: 'Naturfag',
    eksamen: 0,
    standpunkt: 0,
  },
  {
    id: uuid(),
    label: 'Kroppsøving',
    eksamen: 0,
    standpunkt: 0,
  },
  {
    id: uuid(),
    label: 'Musikk',
    eksamen: 0,
    standpunkt: 0,
  },
]
