import React from 'react';
import X from '../images/x.png';
import styles from './Button.module.css';

interface Props {
  clickHandler: (val: string) => void;
  id: string;
  position?: 'default' | 'center';
}

const Button:React.FC<Props> = ({children, clickHandler, id, position = 'default'}) => {
  return (
  <>
  <div className={`${styles.button} ${position === 'center' ? styles.center: styles.default}`} onClick={() => clickHandler(id)}>
    <img className={styles.icon} src={X} alt="fjern emne" />
  </div>
</>
  )
}

export default Button;
