import React from 'react';
import Button from './Button';
import GradeComponent from './GradeComponent';
import styles from './MobileSubjectsTable.module.css';

interface Props {
  rows: ISubject[];
  handleUpdateSubjects: (id: string) => void;
  handleUpdateGrade: (id: string, grade: number, field: IField) => void;
}


const MobileSubjectsTable: React.FC<Props> = ({rows, handleUpdateSubjects, handleUpdateGrade}) => {
  return (
    <>
    {rows.map((row: any, index: number) => (<div className={styles.row} key={index}><div className={styles.subjectName}><p>{row.label}</p><Button clickHandler={handleUpdateSubjects} position={'center'} id={row.id}></Button></div><div><p className={styles.subTitle}>Standpunktkarakter</p><GradeComponent size="lg" grade={row.standpunkt} field='standpunkt' id={row.id} handleUpdateGrade={handleUpdateGrade}/></div><div><p className={styles.subTitle}>Eksamenskarakter</p><GradeComponent size="lg" grade={row.eksamen} field='eksamen' id={row.id} handleUpdateGrade={handleUpdateGrade}/></div></div>))}
    <hr className={styles.hrLine}/>
  </>
  )
}

export default MobileSubjectsTable;
